import { useState } from "react"
import clsx from "clsx"
import Layout from "components/layout"
import { Form, Formik } from "formik"
import Link from "next/link"
import Router from "next/router"
import * as Yup from "yup"
import Animation from "../animations/05_LogIn_DESK.json"
import AnimationMobile from "../animations/05_LogIn_MOB.json"
import AuthBox from "../components/auth-box"
import Input from "../components/text-input"
import useUser from "../lib/useUser"
import AuthIcon from "../svgs/auth.svg"

const Login = () => {
  const { login } = useUser({
    redirectTo: "/my-account",
    redirectIfFound: true,
  })

  const [errorMsg, setErrorMsg] = useState("")

  return (
    <Layout title="Login" noindex>
      <section className="bg-gray u-section-padding-md">
        <div className="container">
          <AuthBox
            title="Log In"
            subtitle="Use your email address or account number."
            animation={{ desktop: Animation, mobile: AnimationMobile }}
          >
            <Formik
              initialValues={{
                login: "",
                password: "",
              }}
              validationSchema={Yup.object({
                login: Yup.string().required().label("Login"),
                password: Yup.string().required().label("Password"),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  await login({
                    login: values.login,
                    password: values.password,
                  })
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  Router.push("/my-account")
                } catch (error: any) {
                  setErrorMsg(error?.message)
                } finally {
                  setSubmitting(false)
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form className="mt-4">
                  {errorMsg && (
                    <div className="alert alert-danger">{errorMsg}</div>
                  )}
                  <div className="form-group">
                    <Input
                      label="Email address or Account number"
                      name="login"
                    />
                  </div>
                  <div className="form-group">
                    <Input label="Password" name="password" type="password" />
                  </div>
                  <div className="row justify-content-between align-items-center">
                    <div className="col-auto">
                      <Link href="/reset-password" className="small">
                        <u>Forgot password?</u>
                      </Link>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        className={clsx(
                          "btn btn-block btn-primary",
                          `${isSubmitting ? "loader" : ""}`,
                        )}
                        disabled={isSubmitting}
                      >
                        <AuthIcon />
                        Log in
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </AuthBox>
        </div>
      </section>
    </Layout>
  )
}

export default Login
