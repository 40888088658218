import React, { useId } from "react"
import clsx from "clsx"
import { useField } from "formik"

type Props = {
  label?: string
  className?: string
  suffix?: string
  type?: "text" | "email" | "password" | "tel" | "hidden" | "number"
}

const TextInput: React.FC<Props & React.HTMLProps<HTMLInputElement>> = ({
  label = undefined,
  type = "text",
  className = "",
  suffix = null,
  ...props
}) => {
  // @ts-ignore
  const [field, meta] = useField(props)
  const id = useId()
  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className={clsx({ "text-danger": meta.error && meta.touched })}
        >
          {label}
        </label>
      )}
      <div className={clsx({ "input-group": suffix })}>
        <input
          className={clsx("form-control", className, {
            "is-invalid": meta.error && meta.touched,
          })}
          id={id}
          type={type}
          {...field}
          {...props}
        />
        {suffix && <span className="input-group-text">{suffix}</span>}
      </div>
      {meta.touched && meta.error && (
        <span className="invalid-feedback d-inline-block">{meta.error}</span>
      )}
    </div>
  )
}

export default TextInput
